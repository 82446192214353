import "./style.scss"

import 'master'
import 'slick-carousel';

var _isMobiClass = 'isMobi',
  _isPopupClass = 'isPopup';
var _isDesktopSize = 1100;
var _inputCheckedClass ='box-checked';

$(window).on('scroll',function(){

  var lastScrollTop = 0;
  var $targetFiltet = $('.comp-filter-sports');

  if ($targetFiltet.is(':visible')) {
    var _targetFilterPos = Math.round($targetFiltet.offset().top+$targetFiltet.find('._wrapper').outerHeight());
    var st = $(this).scrollTop()+$(window).height();
    var _pgBtmPos = Math.round(($('footer .btm-bar').offset().top));

    if($(this).scrollTop()==0 && $(window).width()>1119){
      $('html').addClass('homepage-init');
    }else{
      $('html').removeClass('homepage-init');
    };

      if ($('.btn-filter.hide-mobi').hasClass('on')) {
        $('html').addClass('filter-scroll-down');
        if ($(window).width()>1119) {
          if (st >= _pgBtmPos) {
            $('html').addClass('pg-bottom');
            $('.comp-filter-sports ._wrapper').css('margin-bottom',290); //Math.round(st*0.03)
          }else{
          $('html').removeClass('pg-bottom');
          $('.comp-filter-sports ._wrapper').css('margin-bottom',0);
          }
        }
      }else{
        $('html').removeClass('filter-scroll-down');
      }
    //scroll home filter
    if ($(window).width()>1119 && st < _targetFilterPos) {
       if (st > lastScrollTop){
           $('html').removeClass('filter-scroll-down');
           //console.log('scroll down');
       }
       lastScrollTop = st;
    }
  }

});

$(function() {
  announcementsFn();
  if ($(".popup-wrapper .inner ul").height() > $(".popup-wrapper .inner").height()) {
    $(".popup-wrapper").removeClass("noArrow");
  } else {
    $(".popup-wrapper").addClass("noArrow");
  }  
    

  $('.comp-filter-sports .btn-filter.hide-mobi').click(function() {
    $(this).toggleClass('on');
    if ($(this).hasClass('on')) {
      $(this).parent().find('.opt-list').fadeIn();
      $('html').addClass('filter-scroll-down');
    }else{
      $(this).parent().find('.opt-list').hide();
      $('html').removeClass('filter-scroll-down');
    }
  })
  $('.comp-filter-sports .btn-filter.hide-desktop').click(function() {
    $(this).parent().parent().toggleClass(_isMobiClass);
    $(this).parent().parent().find('.opt-list').addClass('on');
    $(this).parent().parent().addClass(_isPopupClass);
    $('html').addClass(_isPopupClass);
  })
  $('.comp-filter-sports .btn-close').click(function() {
    $(this).parent().parent().removeClass('on');
    $(this).parent().parent().parent().removeClass(_isPopupClass);
    $('html').removeClass(_isPopupClass);
  })
  $('.comp-filter-sports .hitest').click(function() {
    $('.comp-filter-sports .btn-close').trigger('click');
  })


  $('.comp-filter-sports .opt-list input[type="radio"]').change(function() {
    var $this = $(this).parent().parent();
    $('.comp-filter-sports .opt-list').find('.form-check').removeClass(_inputCheckedClass);
    $this.addClass(_inputCheckedClass);
  })

  $('.comp-filter-sports .opt-list .form-check').click(function(){
    $(this).find('input[type="radio"]').trigger('change');
  });

  $('.comp-filter-sports .btn-reset').click(function(){
    $('.comp-filter-sports .opt-list').find('.form-check').removeClass(_inputCheckedClass);
  });

  $('.btn-reset.hide-mobi').click(function(){
    window.location.href = './';
  });

  $(".announce-wrapper").slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    autoplay: true,
    autoplaySpeed: 5000
  });

  //highlight the event
  // var serverTimeHomeArr = serverTime.split("/");
  // var serverTimeHome = "homeT" + serverTimeHomeArr[1] + serverTimeHomeArr[0];
  // $("." + serverTimeHome).addClass("todayHome");
  $(".comp-news-and-events dl").eq(0).addClass("todayHome");
  

});

$(window).on('load', function() {
  homeAjaxData();
  setTimeout(function () {
    //comment on 5 Jul to hide the cookie pop-up
    // displayCookieAnnouncement();
  }, 1000);
});

function announcementsFn() {
  var $parent = $('.module-announcements');
  $('.btn-close', $parent).click(function() {
    $parent.fadeOut();
    $('.btn-filter.hide-mobi').addClass('hiddenAnnouncements');
  });
  $('.btn-all', $parent).click(function() {
    popupFn();
  });
  $('.btn-more', $parent).click(function() {
    popupFn();
  });
  $('.module-popup-event .btn-close').click(function() {
    popupFn();
  });
  $('.announcementItem').each(function(i) {
    $(this).click(function() {
      popupFn();
    });
  });
  $('.announcementItem .btn-more').each(function(i) {
    $(this).click(function() {
      popupFn();
    });
  });

  
  

}

function popupFn() {
  if (!$('html').hasClass(_isPopupClass)) {
    $('html').addClass(_isPopupClass);
    $('.popup-announcements').addClass('on');
  } else {
    $('html').removeClass(_isPopupClass);
    $('.popup-announcements').removeClass('on');
  }
}

function filterOptsChecker(){
  var _selNewsOptId = $('.group-news .form-check.box-checked input').attr('filter-tag');
  var _selSportOptId = $('.group-sports .form-check.box-checked input').attr('filter-tag');
  //console.log(_selNewsOptId, _selSportOptId);
  if (_selSportOptId==undefined) {
    if (_selNewsOptId==undefined) {
      window.location = '/';
    }else{
      window.location = '/?sectiontag='+_selNewsOptId;
    }
  }else if (_selNewsOptId==undefined) {
    if (_selSportOptId=='') {
      window.location = '/?sectiontag=Sports';
    }else{
      window.location = '/?sectiontag=Sports&sportstag='+_selSportOptId;
    }
  }
}

function homeAjaxData() {
  var $postContainer = $('.module-posts');
  var _url = '/Umbraco/api/HomeNews/GetAllHomeNews?page=';

  var _curNewsOpt = getUrlParameter('sectiontag'),
      _curSportOpt = getUrlParameter('sportstag');

  if(_curNewsOpt && $(window).width()>1119){
    $('.btn-filter.hide-mobi').trigger('click');
  }

  if (_curNewsOpt != '') {
    //console.log('urlPara: ', _curNewsOpt, ',', _curSportOpt);

    if (_curSportOpt == '') {
      if (_curNewsOpt == 'Sports') {
        $('.group-sports .form-check:eq(0) input[filter-tag]').trigger('change');
      } else {
        $('input[filter-tag="' + _curNewsOpt + '"]').trigger('change');
      }
    } else {
      $('input[filter-tag="' + _curSportOpt + '"]').trigger('change');
    }
  }

  //console.log('apiURL: ', _url);

  if (!$('.btn-filter.hide-desktop').is(':visible')) {
    $('.form-check input').change(function () {
      filterOptsChecker();
    });
  } else {
    // hidden on 17/2
    $('.comp-filter-sports .btn-close').click(function () {
      filterOptsChecker();
    });
    $('.form-check input').change(function () {
      console.log($(this).attr('id'));
      filterOptsChecker();
    });
  }

  $postContainer.attr('data-post-history', 1); //START: Post item init
  // $.ajax({
  //   url: _url + '1',
  //   context: document.body
  // }).done(function(data) {
  //
  //   data = JSON.parse(JSON.stringify(data).replace(/\:null/gi, "\:\"\""));
  //
  //   //console.log(data.length);
  //   $postContainer.attr('data-post-history', 1);
  //   if (data != null) {
  //     var _postItems = [];
  //     $.each(data, function(i, item) {
  //       //TV schedule
  //       if (item.ContenType == 'tVSchedule') {
  //         //console.log('tVSchedule');
  //         _postItems.push('<div class="component-board-match ' + item.SportsTag + '"> <div class="top-bar bar-color-type-'+(Math.floor(Math.random() * 3) + 1)+'"> <p>' + item.Title + '</p></div><div class="date">' + item.Date + '</div><div class="record"> <div class="home"> <dl class="clearfix"> <dt>0</dt> <dd> <div style="background-image:url(' + item.HomeTeamImageUrl + ')"></div><span>' + item.HomeTeamName + '</span> </dd> </dl> </div><div class="info"><span class="pool">' + item.Description + '</span></div><div class="away"> <dl class="clearfix"> <dd> <div style="background-image:url(' + item.AwayTeamImageUrl + ')"></div><span>' + item.AwayTeamName + '</span> </dd> <dt>0</dt> </dl> </div></div></div>');
  //       }
  //
  //       //scoreboard
  //       if (item.ContenType == 'scoreboard') {
  //         //console.log('scoreboard');
  //         _postItems.push('<div class="component-board-match ' + item.SportsTag + '"> <div class="top-bar bar-color-type-'+(Math.floor(Math.random() * 3) + 1)+'"> <p>' + item.Title + '</p></div><div class="date">' + item.Date + '</div><div class="record live"> <div class="home"> <dl class="clearfix"> <dt>' + item.HomeTeamScore + '</dt> <dd> <div style="background-image:url(' + item.HomeTeamImageUrl + ')"></div><span>' + item.HomeTeamName + '</span> </dd> </dl> </div><div class="info"><span class="pool">' + item.Description + '</span></div><div class="away"> <dl class="clearfix"> <dd> <div style="background-image:url(' + item.AwayTeamImageUrl + ')"></div><span>' + item.AwayTeamName + '</span> </dd> <dt>' + item.AwayTeamScore + '</dt> </dl> </div></div></div>');
  //       }
  //
  //       //thumbs
  //       if (item.ContenType == 'clubNews' && item.ImageUrl != null) {
  //         //console.log(item.ImageUrl.length);
  //         if (item.ImageUrl.length == 1) {
  //           //console.log('clubNews1');
  //           _postItems.push('<div class="comp-post item-color-'+(Math.floor(Math.random() * 3) + 1)+' thumb-lgth-' + (item.ImageUrl.length) + '"><a class="inner" href="' + item.ItemUrl + '"><span class="cat">' + item.ContentTag + '</span> <div class="thumb clearfix"><span style="background-image:url(' + item.ImageUrl[0] + ')"></span> <h2 class="title sm">' + item.Title + '</h2> </div><p class="desc">' + item.Description + '</p><span class="date">' + item.Date + '</span></a></div>');
  //         } else if (item.ImageUrl.length == 2) {
  //           //console.log('clubNews2');
  //           _postItems.push('<div class="comp-post item-color-'+(Math.floor(Math.random() * 3) + 1)+' thumb-lgth-' + (item.ImageUrl.length) + '"><a class="inner" href="' + item.ItemUrl + '"><span class="cat">' + item.ContentTag + '</span> <div class="thumb clearfix"><span style="background-image:url(' + item.ImageUrl[0] + ')"></span><span style="background-image:url(' + item.ImageUrl[0] + ')"></span> <h2 class="title sm">' + item.Title + '</h2> </div><p class="desc">' + item.Description + '</p><span class="date">' + item.Date + '</span></a></div>');
  //         } else if (item.ImageUrl.length == 3) {
  //           //console.log('clubNews3');
  //           _postItems.push('<div class="comp-post item-color-'+(Math.floor(Math.random() * 3) + 1)+' thumb-lgth-' + (item.ImageUrl.length) + '"><a class="inner" href="' + item.ItemUrl + '"><span class="cat">' + item.ContentTag + '</span> <div class="thumb clearfix"><span style="background-image:url(' + item.ImageUrl[0] + ')"></span><span style="background-image:url(' + item.ImageUrl[1] + ')"></span><span style="background-image:url(' + item.ImageUrl[2] + ')"></span> <h2 class="title sm">' + item.Title + '</h2> </div><p class="desc">' + item.Description + '</p><span class="date">' + item.Date + '</span></a></div>');
  //         }
  //       } else if (item.ContenType == 'clubNews' && item.ImageUrl == null) {
  //         //console.log('clubNews: null');
  //         _postItems.push('<div class="comp-post short"><a class="clearfix inner" href="' + item.ItemUrl + '"><span class="cat">' + item.ContentTag + '</span> <p class="desc">' + item.Title + '</p><span class="date">' + item.Date + '</span></a></div>')
  //       }
  //     });
  //   }
  //
  //   console.log(_postItems);
  //   $postContainer.append(_postItems);
  //
  // });
  //END: Post item init

  $('.bar-loader button').click(function () {
    var _nextPgNum = parseInt($postContainer.attr('data-post-history'));
    var apiURL;

    $postContainer.attr('data-post-history', _nextPgNum + 1);
    $('.bar-loader .lds-ring').css('display', 'inline-block'); //START: Post item init
    //console.log('Data Loader: ',_curNewsOpt);
    if (_curNewsOpt) {if (_curNewsOpt != '') {
      //console.log('urlPara: ', _curNewsOpt, ',', _curSportOpt);

      if (_curSportOpt == '') {
        _url = '/Umbraco/api/HomeNews/GetAllHomeNews?page='+ (_nextPgNum + 1) +'&sectiontag=' + _curNewsOpt;
      } else {
        if (_curNewsOpt == 'Sports' && _curSportOpt == '') {
          _url = '/Umbraco/api/HomeNews/GetAllHomeNews?page='+ (_nextPgNum + 1) +'&sectiontag=Sports';
        } else {
          _url = '/Umbraco/api/HomeNews/GetAllHomeNews?page='+ (_nextPgNum + 1) +'&sectiontag=Sports&sportstag=' + _curSportOpt;
        }
      }
    }
      apiURL = _url
    }else{
      apiURL = _url + (_nextPgNum + 1);
    }
    $.ajax({
      url: apiURL,
      context: document.body
    }).done(function (data) {
      data = JSON.parse(JSON.stringify(data).replace(/\:null/gi, "\:\"\""));
      //console.log('Data URL:', _url);
      //console.log('News Data:', data);
      $('.bar-loader .lds-ring').fadeOut('', function () {
        if (data != null) {
          var _postItems = [];
          $.each(data, function (i, item) {
            //TV schedule
            if (item.ContenType == 'tVSchedule' && item.MatchType!=null) {
              var _homeIcon, _awayIcon;

              if (item.MatchType == 'event-opening-day') {

                if (item.HomeTeamImageUrl != null && item.HomeTeamImageUrl != '') {
                  _homeIcon = 'style="background-image: url(' + item.HomeTeamImageUrl + ')"'
                }else{
                  _homeIcon = ''
                }
                _postItems.push('<a href="/news-and-events/tv-schedule"><div class="component-board-match ' + item.ClassName + ' event-opening"> <div class="top-bar bar-color-type-' + (Math.floor(Math.random() * 3) + 1) + '"> <p>' + item.Title + '</p> </div> <div class="date">' + item.Date + '</div> <div class="record"> <div class="details"> <dl class="clearfix"> <dd> <div '+_homeIcon+'></div> <span>' + item.HomeTeamName + '</span> </dd> </dl> </div> </div> </div></a>');
              }else if(item.MatchType == 'hkfc-match') {
                _postItems.push('<a href="/news-and-events/tv-schedule"><div class="component-board-match hkfc players"> <div class="top-bar bar-color-type-' + (Math.floor(Math.random() * 3) + 1) + '"> <p>' + item.Title + '</p> </div> <div class="date">' + item.Date + '</div> <div class="record"> <div class="home"> <dl class="clearfix"> <dd><span>Team A</span></dd> </dl> </div> <div class="info"><span class="pool">VS</span></div> <div class="away"> <dl class="clearfix"> <dd><span>Team B</span></dd> </dl> </div> </div> </div></a>');
              }else if (item.MatchType == 'single-or-double-match') {
                var _homeTeamName = item.HomeTeamName.split(","),
                    _awayTeamName = item.AwayTeamName.split(",");

                if (_homeTeamName.length<=1) {
                  _postItems.push('<a href="/news-and-events/tv-schedule"><div class="component-board-match ' + item.ClassName + ' players"> <div class="top-bar bar-color-type-' + (Math.floor(Math.random() * 3) + 1) + '"> <p>' + item.Title + '</p> </div> <div class="date">' + item.Date + '</div> <div class="record"> <div class="home"> <dl class="clearfix"> <dd> <span>' + _homeTeamName[0] + '</span> </dd> </dl> </div> <div class="info"><span class="pool">VS</span></div> <div class="away"> <dl class="clearfix"> <dd> <span>' + _awayTeamName[0] + '</span> </dd> </dl> </div> </div> </div></a>');
                }else {
                  _postItems.push('<a href="/news-and-events/tv-schedule"><div class="component-board-match ' + item.ClassName + ' players"> <div class="top-bar bar-color-type-' + (Math.floor(Math.random() * 3) + 1) + '"> <p>' + item.Title + '</p> </div> <div class="date">' + item.Date + '</div> <div class="record"> <div class="home"> <dl class="clearfix"> <dd> <span>' + _homeTeamName[0] + '</span><span>' + _homeTeamName[1] + '</span> </dd> </dl> </div> <div class="info"><span class="pool">VS</span></div> <div class="away"> <dl class="clearfix"> <dd> <span>' + _awayTeamName[0] + '</span><span>' + _awayTeamName[1] + '</span> </dd> </dl> </div> </div> </div></a>');
                }

              }else{
                
                if (item.Description=='') {
                  item.Description = 'VS';
                }
                if (item.HomeTeamImageUrl != null && item.HomeTeamImageUrl != '') {
                  
                  _homeIcon = 'style="background-image: url(' + item.HomeTeamImageUrl + ')"'
                }else{
                  
                  _homeIcon = ''
                }
                if (item.AwayTeamImageUrl != null && item.AwayTeamImageUrl != '') {
                  
                  _awayIcon = 'style="background-image: url(' + item.AwayTeamImageUrl + ')"'
                }else{
                  
                  _awayIcon = ''
                }
                _postItems.push('<a href="/news-and-events/tv-schedule"> <div class="component-board-match ' + item.ClassName + '"> <div class="top-bar bar-color-type-' + (Math.floor(Math.random() * 3) + 1) + '"> <p>' + item.Title + '</p></div><div class="date">' + item.Date + '</div><div class="record"> <div class="home"> <dl class="clearfix"> <dt>0</dt> <dd> <div '+_homeIcon+'></div><span>' + item.HomeTeamName + '</span> </dd> </dl> </div><div class="info"><span class="pool">' + item.Description + '</span></div><div class="away"> <dl class="clearfix"> <dd> <div '+_awayIcon+'"></div><span>' + item.AwayTeamName + '</span> </dd> <dt>0</dt> </dl> </div></div></div></a>');
              }
            }

            //scoreboard


            if (item.ContenType == 'scoreboard') {
              if (item.Description=='') {
                item.Description = 'VS';
              }
              console.log("344 home")
              if (item.HomeTeamImageUrl != null && item.HomeTeamImageUrl != '') {
                  
                _homeIcon = 'style="background-image: url(' + item.HomeTeamImageUrl + ')"'
              }else{
                
                _homeIcon = ''
              }
              if (item.AwayTeamImageUrl != null && item.AwayTeamImageUrl != '') {
                
                _awayIcon = 'style="background-image: url(' + item.AwayTeamImageUrl + ')"'
              }else{
                
                _awayIcon = ''
              }
              // _postItems.push('<div class="component-board-match ' + item.ClassName + '"> <div class="top-bar bar-color-type-' + (Math.floor(Math.random() * 3) + 1) + '"> <p>' + item.Title + '</p></div><div class="date">' + item.Date + '</div><div class="record live"> <div class="home"> <dl class="clearfix"> <dt>' + item.HomeTeamScore + '</dt> <dd> <div style="background-image:url(' + item.HomeTeamImageUrl + ')"></div><span>' + item.HomeTeamName + '</span> </dd> </dl> </div><div class="info"><span class="pool">' + item.Description + '</span></div><div class="away"> <dl class="clearfix"> <dd> <div style="background-image:url(' + item.AwayTeamImageUrl + ')"></div><span>' + item.AwayTeamName + '</span> </dd> <dt>' + item.AwayTeamScore + '</dt> </dl> </div></div></div>');
              if (item.ItemUrl != '') {
                _postItems.push('<a href="' + item.ItemUrl + '" target="_blank"><div class="component-board-match ' + item.ClassName + '"> <div class="top-bar bar-color-type-' + (Math.floor(Math.random() * 3) + 1) + '"> <p>' + item.Title + '</p></div><div class="date">' + item.Date + '</div><div class="record live"> <div class="home"> <dl class="clearfix"> <dt>' + item.HomeTeamScore + '</dt> <dd> <div '+_homeIcon+'></div><span>' + item.HomeTeamName + '</span> </dd> </dl> </div><div class="info"><span class="pool">' + item.Description + '</span></div><div class="away"> <dl class="clearfix"> <dd> <div '+_awayIcon+'"></div><span>' + item.AwayTeamName + '</span> </dd> <dt>' + item.AwayTeamScore + '</dt> </dl> </div></div></div></a>');
              } else {
                _postItems.push('<div class="component-board-match ' + item.ClassName + '"> <div class="top-bar bar-color-type-' + (Math.floor(Math.random() * 3) + 1) + '"> <p>' + item.Title + '</p></div><div class="date">' + item.Date + '</div><div class="record live"> <div class="home"> <dl class="clearfix"> <dt>' + item.HomeTeamScore + '</dt> <dd> <div '+_homeIcon+'></div><span>' + item.HomeTeamName + '</span> </dd> </dl> </div><div class="info"><span class="pool">' + item.Description + '</span></div><div class="away"> <dl class="clearfix"> <dd> <div '+_awayIcon+'"></div><span>' + item.AwayTeamName + '</span> </dd> <dt>' + item.AwayTeamScore + '</dt> </dl> </div></div></div>');
              }
              
            }

            //thumbs


            if (item.ContenType == 'clubNews') {
              var _catName;

              if (item.SectionTag == null || item.SectionTag == 'Sports') {
                _catName = item.SportsTag;
              } else {
                _catName = item.SectionTag;
              }

              var urlTar = "";
              if (item.ItemUrlTarget == "_blank") {
                urlTar = item.ItemUrlTarget;
              } 

              if (item.ImageUrl.length == 1) {
                _postItems.push('<div class="comp-post item-color-' + (Math.floor(Math.random() * 3) + 1) + ' thumb-lgth-' + item.ImageUrl.length + '"><a class="inner" href="' + item.ItemUrl + '" target="' + urlTar + '"><span class="cat">' + item.ContentTag + '</span> <div class="thumb clearfix"><span style="background-image: url(' + item.ImageUrl[0] + ')"></span><h2 class="title sm">' + _catName + '</h2> </div><p class="desc">' + item.Title + '</p><span class="date">' + item.Date + '</span></a></div>');
              } else if (item.ImageUrl.length == 2) {
                _postItems.push('<div class="comp-post item-color-' + (Math.floor(Math.random() * 3) + 1) + ' thumb-lgth-' + item.ImageUrl.length + '"><a class="inner" href="' + item.ItemUrl + '" target="' + urlTar + '"><span class="cat">' + item.ContentTag + '</span> <div class="thumb clearfix"><span style="background-image: url(' + item.ImageUrl[0] + ')"></span><span style="background-image: url(' + item.ImageUrl[1] + ')"></span><h2 class="title sm">' + _catName + '</h2> </div><p class="desc">' + item.Title + '</p><span class="date">' + item.Date + '</span></a></div>');
              } else if (item.ImageUrl.length == 3) {
                _postItems.push('<div class="comp-post item-color-' + (Math.floor(Math.random() * 3) + 1) + ' thumb-lgth-' + item.ImageUrl.length + '"><a class="inner" href="' + item.ItemUrl + '" target="' + urlTar + '"><span class="cat">' + item.ContentTag + '</span> <div class="thumb clearfix"><span style="background-image: url(' + item.ImageUrl[0] + ')"></span><span style="background-image: url(' + item.ImageUrl[1] + ')"></span><span style="background-image: url(' + item.ImageUrl[2] + ')"></span><h2 class="title sm">' + _catName + '</h2> </div><p class="desc">' + item.Title + '</p><span class="date">' + item.Date + '</span></a></div>');
              } else if (item.ImageUrl.length == 0) {
                _postItems.push('<div class="comp-post short"><a class="clearfix inner" href="' + item.ItemUrl + '" target="' + urlTar + '"><span class="cat">' + item.ContentTag + '</span> <p class="desc">' + item.Title + '</p><span class="date">' + item.Date + '</span></a></div>');
              }
            }

          });
          $postContainer.append(_postItems);
        }else{
          $('.bar-loader').fadeOut();
        }
      }); //console.log(_url+(_nextPgNum+1));
      //console.log('Page Data:',data);
      if (data!=null) {
        if (data.length < 6) {
          $('.bar-loader').fadeOut();
        }
      }
    }); //END: Post item init
  });
}

function getUrlParameter(name) {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  var results = regex.exec(location.href);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, '    '));
};

function setCookie(name,value,days) {
  var expires = "";
  if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days*24*60*60*1000));
      expires = "; expires=" + date.toGMTString();
  }
  document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}
function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for(var i=0;i < ca.length;i++) {
      var c = ca[i];
      while (c.charAt(0)==' ') c = c.substring(1,c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
  }
  return null;
}

function displayCookieAnnouncement() {
  //check announcement pop-up cookie
  if (getCookie('_popAnnouncement')) {

  } else {
    $(".module-announcements .btn-all").trigger("click");
    
    setCookie('_popAnnouncement', "Y");
  }
}
  

$(window).on('resize', function() {
  var win = $(this); //this = window
  if (win.width() >= _isDesktopSize) {
    //console.log('it's desktop');
  } else {
    //console.log('it's mobile');
    if ($('.btn-filter.hide-mobi').hasClass('on')) {
      $('.btn-filter.hide-mobi').trigger('click');
    }
  }

  if ($(".popup-wrapper .inner ul").height() > $(".popup-wrapper .inner").height()) {
    $(".popup-wrapper").removeClass("noArrow");
  } else {
    $(".popup-wrapper").addClass("noArrow");
  }
});
